import $ from './libraries/jquery.min';

// GA4
window.dataLayer = window.dataLayer || [];
function gtag() {dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-RKCYKLJ75C');

(function() {
	var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
	ga.src = 'https://www.googletagmanager.com/gtag/js?id=G-RKCYKLJ75C';
	var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);

	// Set active page based on the URL
	switch (window.location.pathname) {
		case '/about':
		case '/about/':
				$('a[href="/about"]').addClass('active');
			break;
		case '/contact':
		case '/contact/':
			$('a[href="/contact"]').addClass('active');
			break;
		default:
			$('a[href="/"]').addClass('active');
	}

})();

// Functions
function switchLogos(evt) {
	evt.preventDefault();
	$('svg.logo').toggleClass('active');
	$('span.japanese_note').toggleClass('show');
}

// Init
$(document).ready(function(){
	$('svg.logo').on('click', switchLogos);
});